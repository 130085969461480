<template>
  <v-dialog v-model="dialog" fullscreen @click:outside="clickOutside">
    <template v-slot:activator="{ on, attrs }">
      <p id="assetImport" v-bind="attrs" v-on="on"></p>
    </template>
    <div v-if="permission.read_perm === 1" style="overflow:hidden;">
      <v-form
        :disabled="permission.create_perm == 0"
        ref="entryForm"
        @submit.prevent="submit"
        style="position: relative; background:white;min-height:100vh;"
      >
        <v-card
          flat
          style="
                z-index: 2;
                position: sticky;
                top: 0;
                background: white;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
              "
        >
          <div
            style="
                  display: flex;
                  jsutify-content: center;
                  align-items: center;
                  margin-left: 10px;
                "
          >
            <v-toolbar-title class="overline">
              <p
                v-if="wWidth > 780"
                style="font-size: 20px; margin: 0; font-weight: bold"
              >
                Pengelolaan Asset (Import)
              </p>
              <p v-else style="font-size: 14px; margin: 0; font-weight: bold">
                Pengelolaan Asset (Import)
              </p>
            </v-toolbar-title>
          </div>
          <div>
            <v-btn
              small
              outlined
              color="red"
              class="ma-2"
              style="color: white"
              @click="close"
            >
              <v-icon small class="me-2">mdi-close</v-icon>
              Tutup
            </v-btn>
          </div>
        </v-card>

        <v-card
          elevation="2"
          class=" mx-auto"
          style="padding:10px 15px 20px 15px; width:99%; margin-top:30px;"
        >
          <v-row>
            <v-col cols="12">
              <v-toolbar flat style="border-radius:10px 10px 0 0;">
                <v-btn class="mx-2 elevation-4" dark small color="cyan">
                  <v-icon small dark>
                    mdi-upload
                  </v-icon>
                </v-btn>
                <!-- <v-icon style="margin-right: 10px;">mdi-handshake</v-icon> -->
                <v-toolbar-title>Upload File</v-toolbar-title>
              </v-toolbar>
            </v-col>
            <v-col cols="12" style="padding:0;">
              <p style="margin:0; font-size:14px; padding-left:35px;">
                Anda dapat mendownload template untuk uploadnya
                <a href="https://bit.ly/3Af3R5f" target="__blank">disini!</a>
              </p>
            </v-col>
            <v-col cols="12" style="padding-left:35px;">
              <!-- <p style="border-top:5px solid grey;"></p> -->
            </v-col>
            <v-col cols="12" style="padding: 0 0 30px 30px;">
              <v-row>
                <v-col cols="8" md="10">
                  <v-file-input
                    show-size
                    label="File input"
                    v-model="uploadFile"
                  />
                </v-col>
                <v-col cols="2">
                  <!-- a -->
                  <v-btn
                    small
                    color="primary"
                    class="ma-2 white--text"
                    @click="assetImport"
                    :disabled="uploadFile === null"
                    :loading="loading"
                  >
                    Upload
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          elevation="2"
          class=" mx-auto"
          style="padding:20px 10px; width:99%; margin-top:20px;"
        >
          <v-row>
            <v-col cols="12">
              <div style=" overflow:auto;">
                <v-data-table
                  dense
                  mobile-breakpoint="0"
                  :headers="type === 1 ? headers1 : headers2"
                  :items="importData"
                  class="elevation-1 mx-auto row-pointer"
                  style="margin-top: 20px; margin-bottom:20px;min-width:1200px; max-width:99%;"
                  :items-per-page="itemsPerPage"
                  :item-class="itemRowBackground"
                >
                  <template v-slot:top>
                    <v-toolbar
                      flat
                      style="height:100px; border-radius:10px 10px 0 0; border-bottom:1px solid #e0e0e0;"
                    >
                      <v-btn class="mx-2 elevation-4" dark small color="cyan">
                        <v-icon small dark>
                          mdi-clipboard-list-outline
                        </v-icon>
                      </v-btn>
                      <v-toolbar-title>Tabel hasil upload</v-toolbar-title>
                    </v-toolbar>
                  </template>
                  <template v-if="type === 1" v-slot:[`item.id`]="{ item }">
                    <div style="width:100px;overflow:hidden;">
                      <div
                        style="font-size:12px; padding: 0; display: flex; flex-direction: row;"
                      >
                        {{ item.id }}
                      </div>
                    </div>
                  </template>
                  <template v-slot:[`item.status_code`]="{ item }">
                    <div style="width:200px;overflow:hidden;">
                      <div
                        :style="
                          `font-size:12px; padding: 0; display: flex; flex-direction: row;`
                        "
                      >
                        {{ importStatus(item.status_code) }}
                      </div>
                    </div>
                  </template>
                  <template v-slot:[`item.lot_no`]="{ item }">
                    <div
                      :style="
                        `font-size:12px; padding: 0; display: flex; flex-direction: row;`
                      "
                    >
                      {{ item.lot_no }}
                    </div>
                  </template>
                </v-data-table>
              </div>
              <p class="caption" style="margin:0;">
                Total Import Berhasil: {{ totalSuccess }}
              </p>
              <p class="caption" style="margin:0;">
                Total Import Gagal: {{ totalFailed }}
              </p>
              <v-col
                v-if="permission.create_perm == 1"
                cols="12"
                style="padding:0 5px;"
              >
                <v-btn
                  small
                  style="margin-right:20px;"
                  color="success"
                  height="30px"
                  width="100px"
                  :disabled="!isImport"
                  @click="saveAssetImport"
                  :loading="loading"
                >
                  Submit Data
                </v-btn>
                <v-btn
                  small
                  style="margin-right:20px;"
                  color="pink"
                  height="30px"
                  width="100px"
                  class="white--text"
                  @click="remove"
                  :loading="loading"
                  :disabled="importData.length === 0"
                >
                  Remove
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </div>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import buildType from '../../../../services/buildType'
export default {
  name: 'Archive-Form',
  props: ['param', 'permission'],
  data() {
    return {
      wWidth: window.innerWidth,
      // eSanqua: buildType.apiURL('esanqua'),
      upload: buildType.apiURL('upload3'),
      // download: buildType.apiURL('download'),
      asset: buildType.apiURL('asset'),
      dialog: false,
      form: {},
      rules: {},
      loading: false,
      select: null,
      search: null,
      items: [],
      new: [],

      isUpload: false,
      uploadFile: null,
      isImport: false,
      itemsPerPage: 10,
      type: 1,
      totalSuccess: 0,
      totalFailed: 0,
      headers1: [
        {
          text: 'ID',
          value: 'id',
          align: 'left',
          sortable: false,
          width: '15%'
        },
        {
          text: 'Lot Number',
          value: 'lot_no',
          align: 'left',
          sortable: false,
          width: '15%'
        },
        {
          text: 'Name',
          value: 'name',
          align: 'left',
          sortable: false,
          width: '25%'
        },
        {
          text: 'Department ID',
          value: 'department_id',
          align: 'left',
          sortable: false,
          width: '15%'
        },
        {
          text: 'Department Name',
          value: 'department_name',
          align: 'left',
          sortable: false,
          width: '15%'
        },
        {
          text: 'Import Status',
          value: 'status_code',
          align: 'left',
          sortable: false,
          width: '15%'
        }
      ],

      headers2: [
        {
          text: 'Lot Number',
          value: 'lot_no',
          align: 'left',
          sortable: false,
          width: '20%'
        },
        {
          text: 'Name',
          value: 'name',
          align: 'left',
          sortable: false,
          width: '30%'
        },
        {
          text: 'Message',
          value: 'status_msg',
          align: 'left',
          sortable: false,
          width: '30%'
        },
        {
          text: 'Import Status',
          value: 'status_code',
          align: 'left',
          sortable: false,
          width: '20%'
        }
      ],
      importData: []
    }
  },
  computed: {
    ...mapGetters([])
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    clear() {
      this.uploadFile = null
      this.importData = []
      this.isImport = false
      this.type = 1
      this.totalSuccess = 0
      this.totalFailed = 0
      this.$emit('loadPage')
    },
    clickOutside() {
      this.clear()
    },
    close() {
      this.clear()
      setTimeout(() => {
        this.dialog = false
      }, 200)
    },
    // filterDate(date) {
    //   const day = date.slice(0, date.indexOf('-'))
    //   const month = date.slice(date.indexOf('-') + 1, date.lastIndexOf('-'))
    //   const year = date.slice(date.lastIndexOf('-') + 1)
    //   return year + '-' + month + '-' + day
    // },
    remove() {
      this.type = 1
      this.importData = []
      this.isImport = false
      this.totalSuccess = 0
      this.totalFailed = 0
    },
    async assetImport() {
      this.importData = []
      this.type = 1
      if (
        this.uploadFile.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const data = new FormData()
        data.append('file', this.uploadFile)
        await axios
          .post(`${this.upload}esanqua/upload_from_excel`, data)
          .then(res => {
            console.log(res)
            if (res.data.status_code === '00') {
              for (let i = 0; i < res.data.data.length; i++) {
                // if (res.data.data[i].name !== '') {
                const param = {
                  lot_no: res.data.data[i].lot_no,
                  name: res.data.data[i].name,
                  department_name: res.data.data[i].department_name,
                  department_id: res.data.data[i].department_id,
                  status_code: null
                }
                if (res.data.data[i].id !== undefined) {
                  Object.assign(param, {
                    id: res.data.data[i].id
                  })
                }
                this.importData.push(param)
                // }
              }
              console.log(this.importData)
              this.uploadFile = null
              this.isImport = true
            } else {
              this.showMsgDialog('error', res.data.err_msg)
              this.importData = []
              this.isImport = false
            }
          })
          .catch(err => {
            console.log(err)
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
            this.importData = []
            this.isImport = false
          })
      } else {
        this.showMsgDialog('error', 'File type must be .xlsx', false)
        this.clear()
      }
    },
    saveAssetImport() {
      this.loading = true
      const form = {
        act: 'add',
        data: this.importData
      }
      axios
        .post(`${this.asset}asset/lot/import`, form)
        .then(res => {
          console.log(res)
          if (res.data.status_code === '00') {
            this.importData = []
            this.type = 2
            this.showMsgDialog('success', res.data.status_msg, false)
            for (let i = 0; i < res.data.result_import.length; i++) {
              const param = {
                lot_no: res.data.result_import[i].lot_no,
                name: res.data.result_import[i].name,
                status_msg: res.data.result_import[i].status_msg,
                status_code: res.data.result_import[i].status_code
              }
              this.importData.push(param)

              if (res.data.result_import[i].status_code === '00') {
                this.totalSuccess = this.totalSuccess + 1
              }

              if (res.data.result_import[i].status_code === '-99') {
                this.totalFailed = this.totalFailed + 1
              }
            }
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
            this.importData = []
          }
          this.isImport = false
          this.loading = false
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          this.remove()
        })
    },
    colorStatus(val) {
      if (val === '00') {
        return 'green'
      }
      if (val === '-99') {
        return 'red'
      }
      return 'black'
    },
    importStatus(val) {
      if (val === '00') {
        return 'Import Success'
      }
      if (val === '-99') {
        return 'Import Error'
      }
      return '-'
    },
    itemRowBackground(item) {
      if (item.status_code === '-99') {
        return 'red'
      }
      return ''
    }
  }
}
</script>
<style lang="scss" scoped>
.red {
  background-color: red;
}
.arch-add-new-btn {
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
  &:hover {
    color: blue;
    text-decoration: underline;
  }
}
</style>
